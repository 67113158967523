import { useNavigate } from "react-router-dom";
import { menuItems } from "../../API/menuItems";
import Email from "../../images/footer/email-w.png";
import Marker from "../../images/footer/marker-w.png";
import Whatsapp from "../../images/footer/whatsapp-w.png";
import Logo from "../../images/logo.png";
import "./Footer.css";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer>
      <section className="Footer">
        <div className="Footer_div">
          <button
            className="Footer_logo-container"
            onClick={() => navigate("/")}
          >
            <img className="Footer_logo" src={Logo} alt="Logo" />
          </button>
          <a
            href="mailto:Sales@Beledcosmetics.com"
            target="_blank"
            rel="noreferrer"
            aria-label="E-Mail link opens in a new tab"
            className="Footer_link"
          >
            <img src={Email} alt="Email" className="Footer_icon" />
            Sales@Beledcosmetics.com
          </a>
          <a
            href="https://wa.me/+8618018796760"
            target="_blank"
            rel="noreferrer"
            aria-label="Whatsapp link opens in a new tab"
            className="Footer_link"
          >
            <img src={Whatsapp} alt="Whatsapp" className="Footer_icon" />
            +86-18018796760
          </a>
          <a
            href="https://www.google.com/maps/place/Wuhe+Avenue/@22.638193,114.0574617,17z/data=!3m1!4b1!4m6!3m5!1s0x34038b5d4fe0a689:0x648677d93a6ac97d!8m2!3d22.638193!4d114.060042!16s%2Fg%2F11cm1hrgc2?authuser=0&entry=ttu"
            target="_blank"
            rel="noreferrer"
            aria-label="Office link opens in a new tab"
            className="Footer_link"
          >
            <img src={Marker} alt="Address" className="Footer_icon" />
            Room 202, Wuhe Avenue, Longgang District, Shenzhen City, Guangdong
            Province
          </a>
        </div>

        <div className="Footer_div">
          <h3 className="Footer_title">Product Series</h3>
          {menuItems.map((menu) => {
            if (menu.title === "Products" && menu.submenu) {
              return menu.submenu.map((submenuItem, subIndex) => (
                <p
                  key={subIndex}
                  className="Footer_subtitle"
                  tabIndex={0}
                  onClick={() => navigate(submenuItem.url)}
                >
                  {submenuItem.title}
                </p>
              ));
            } else {
              return null;
            }
          })}
        </div>

        <div className="Footer_div">
          <h3 className="Footer_title">Services</h3>
          {menuItems.map((menu) => {
            if (menu.title === "Services" && menu.submenu) {
              return menu.submenu.map((submenuItem, subIndex) => (
                <p
                  key={subIndex}
                  className="Footer_subtitle"
                  tabIndex={0}
                  onClick={() => navigate(submenuItem.url)}
                >
                  {submenuItem.title}
                </p>
              ));
            } else {
              return null;
            }
          })}
        </div>

        <div className="Footer_div">
          <h3 className="Footer_title">Navigation</h3>
          {menuItems.map((menu, index) => {
            return (
              <p
                key={index}
                className="Footer_subtitle"
                tabIndex={0}
                onClick={() => navigate(menu.url)}
              >
                {menu.title}
              </p>
            );
          })}
        </div>
      </section>

      <section className="Footer_rights">
        <a
          className="footer__rights-text"
          href="https://roeilulavy.github.io/portfolio/"
          target="_blank"
          rel="noreferrer"
          aria-label="Royal.prod home page opens in a new tab"
        >
          © {new Date().getFullYear()} - All Rights Reserved by Royal.Prod
        </a>
      </section>
    </footer>
  );
}

export default Footer;
