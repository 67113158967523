export const menuItems = [
  {
    title: "Home",
    menu: "home",
    url: "/",
  },
  {
    title: "Products",
    menu: "Products",
    url: "/Products",
    submenu: [
      {
        title: "New Arrivals",
        menu: "Products",
        url: "/Products/New-Arrivals",
      },
      {
        title: "NR/WR Series",
        menu: "Products",
        url: "/Products/NR-WR-Series",
      },
      {
        title: "Airless",
        menu: "Products",
        url: "/Products/Airless",
      },
      {
        title: "Bottles",
        menu: "Products",
        url: "/Products/Bottles",
      },
      {
        title: "Makeup",
        menu: "Products",
        url: "/Products/Makeup",
      },
      {
        title: "Jars",
        menu: "Products",
        url: "/Products/Jars",
      },
      {
        title: "Droppers",
        menu: "Products",
        url: "/Products/Droppers",
      },
      {
        title: "Bamboo",
        menu: "Products",
        url: "/Products/Bamboo",
      },
    ],
  },
  {
    title: "Services",
    menu: "services",
    url: "/services/wholesale",
    submenu: [
      {
        title: "Wholesale",
        menu: "services",
        url: "/services/wholesale",
      },
      {
        title: "OEM",
        menu: "services",
        url: "/services/oem",
      },
      {
        title: "ODM",
        menu: "services",
        url: "/services/odm",
      },

      {
        title: "Quality Control",
        menu: "services",
        url: "/services/quality-control",
      },
      {
        title: "Shippping",
        menu: "services",
        url: "/services/shipping",
      },
    ],
  },
  {
    title: "FAQ",
    menu: "blog",
    url: "/blog",
  },
  {
    title: "About Beled",
    menu: "about",
    url: "/about",
  },

  {
    title: "Contact us",
    menu: "contact",
    url: "/contact",
  },
];
